import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/blastring/blasting-main.jpg";
import img_1 from '../images/blastring/blastring.jpg';
import img_2 from '../images/blastring/blastring_1.jpg';
import favicon from '../images/favicon.ico';

function Blastring() {
    return (
        <Layout>
            <Helmet>
                <title>Blastring - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Isblästring även kallat kolsyreblästring är en torr blästringsmetod utan avfall. Isblästring är en effektiv, lätt och miljövänlig rengöringsmetod." />
                <meta name="keywords" content="Isblästring, kolsyreblästring, miljövänlig, rengöringsmetod" />
                <meta property="og:title" content="Montering - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/blastring" />
                <link rel="cannonical" href="https://danasplat.se/blastring" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-blastring bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Isblästring / kolsyreblästring</h1>
                    </div>
                </div>
                <Breadcrumb page="Blästring"/>
            </section>

            <section className='relative'>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Isblästring / kolsyreblästring</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Isblästring även kallat kolsyreblästring är en torr blästringsmetod utan avfall. Isblästring är en effektiv, lätt och miljövänlig rengöringsmetod. Danas Plåt och Smide har utrustning och expertis för alla typer av uppdrag. Vare sig du behöver rengöra en hel industrianläggning eller ett litet kopplingsskåp.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi har även en fast blästeranläggning där vi stålblästrar med steelgrit G-25 med storlek 0.42 – 1.18 mm. Vi kan blästra upp till 23m (24,4m).</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Välkommen att kontakta oss!</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Isblästring" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr className=""/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-24">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="kolsyreblästring i Södertälje" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="stålblästring   " className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Blastring;